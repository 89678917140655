<template>
  <div class="home">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-if="materialList.length !==0" label="未完了の教材" name="first" style="border-color: #F29898;">
        <div v-loading="tableIsLoading" style="display: flex;justify-content: center; border:2px solid #F29898;">
          <div class="app-list-wrap-home-teachingMaterial">
            <div class="app-list-home-teachingMaterial" v-for="(item, index) in materialListCurrent" :key="index">
              <div class="app-list-top">
                <div>
                  <el-image class="app-list-top-img" :src="item.teachingMaterialImagePath"
                            alt="コース画像" fit="contain" @click="showTeachingMaterial(item)">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div class="app-list-course-title">{{item.courseTitle}}</div>
                <!-- <div>{{item.subjectTitle}}</div>
                <div>{{item.unitName}}</div> -->
                <div class="app-list-teaching-material-title" @click="showTeachingMaterial(item)">{{item.teachingMaterialTitle}}</div>
                <!-- (必須/{{item.contentType === '01' ? '動画)':item.contentType === '03' ? 'pdf)':'HTML)'}} -->
              </div>           
            </div>
          </div> 
        </div>
        <div class="app-list-wrap-home-page-calculate">
          <div v-for="item of teachingMaterialPageCalculate" :key="item">
            <div :class="item===teachingMaterialpage ? 'app-page-calculate-click': 'app-page-calculate'" @click="teachingMaterialpageChange(item)"></div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="testList.length !==0" label="未完了のテスト" name="second">
        <div v-loading="tableIsLoading" style="display: flex;justify-content: center;border:2px solid #DEEBF7;">
          <div class="app-list-wrap-home-teachingMaterial">
            <div class="app-list-home-teachingMaterial" v-for="(item, index) in testListCurrent" :key="index">
              <div class="app-list-top">
                <div>
                  <el-image class="app-list-top-img" :src="item.unitIamgePath"
                            alt="コース画像" fit="contain" @click="goTest(item)">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div class="app-list-course-title">{{item.courseTitle}}</div>
                <!-- <div>{{item.subjectTitle}}</div>
                <div>{{item.unitName}}</div> -->
                <div class="app-list-teaching-material-title" @click="goTest(item)">{{item.testTitle}}</div>
              </div>           
            </div>
          </div> 
        </div>
        <div class="app-list-wrap-home-page-calculate">
          <div v-for="item of testListPageCalculate" :key="item">
            <div :class="item===testListpage ? 'app-page-calculate-click': 'app-page-calculate'" @click="testListpageChange(item)"></div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="app-list-sorter-wrap clearfix">
<!--      <el-alert-->
<!--        :title="'受講可能な講座は'+this.listTotal+'件です。'"-->
<!--        type="success"-->
<!--        :closable="false"-->
<!--      >-->
<!--      </el-alert> -->
      <!-- <div class="app-list-sorter-page">
        <span style="font-weight:bold">
          受講可能の講座は{{this.listTotal}}件です。
        </span>
        {{pageText}}
      </div> -->
      <span class="app-list-title">
        講座一覧
      </span>
      <!-- <div class="app-list-sorter-page" style="text-align: right;margin-top:10px;">
        <span style="font-weight:bold;">
          新着順
        </span>
      </div> -->
      <!-- <div class="app-list-sorter-right">
        <span>並び替え</span>
        <el-select v-model="sortBy" size="medium" style="width: 110px">
          <el-option
            v-for="item in sortByOptions"
            :key="item.key"
            :label="item.value"
            :value="item.key">
          </el-option>
        </el-select>
      </div> -->
    </div>
    <div v-loading="tableIsLoading">
      <div class="app-list-wrap">
        <div class="app-list" v-for="(item, index) in courseList" :key="index">
          <div class="app-list-left">
            <el-image class="app-list-left-img" :src="item.courseImagePath"
              alt="コース画像" fit="contain"
              @click="goDetail(item.courseId)"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div class="app-list-right">
            <div>
              <div class="app-list-right-title" @click="goDetail(item.courseId)">
                {{item.courseTitle}}
              </div>
              <!-- <router-link :to="'/course/'+item.id">{{item.title}}</router-link> -->
              <div class="app-list-right-cateandtag">
                <div class="app-list-right-category">
                  <span class="app-list-right-category-title">カテゴリ</span>
                  <span>{{item.courseTypeName}}</span>
                </div>
                <!-- <div class="app-list-right-tags">
                  <div class="app-list-right-tag" v-for="tag in item.tags" :key="tag">
                    {{tag}}
                  </div>
                </div> -->
              </div>
              <div class="app-list-right-desc"><strong style="white-space:pre-wrap; word-wrap:break-word;line-height:1.5em">{{item.courseOutline}}</strong></div>
            </div>
            <!-- <div class="app-list-right-timeandauthor">
              <div class="app-list-right-time">学習時間の目安：{{item.time}}分</div>
              <div class="app-list-right-author">Provided by：{{item.author}}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <el-pagination
      background
      :page-size="listSize"
      :pager-count="7"
      layout="prev, pager, next"
      :total="listTotal"
      @current-change="pageChange"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import request from '@/utils/request';
// import CourseSearch from '@/components/CourseSearch.vue';

export default {
  name: 'Home',
  components: {
    // CourseSearch,
  },
  data() {
    return {
      tableIsLoading: false,
      listTotal: 325,
      listPage: 1,
      listSize: 20,
      sortBy: '0',
      sortByOptions: [
        {
          key: '0',
          value: '新着順',
        },
        {
          key: '1',
          value: '人気順',
        },
        {
          key: '2',
          value: '評価順',
        },
      ],
      courseList: [],
      materialList: [],
      teachingMaterialPageCalculate: 0,
      teachingMaterialpage: 1,
      materialListCurrent:[],
      activeName: 'first',
      testList: [],
      testListPageCalculate: 0,
      testListpage: 1,
      testListCurrent:[]
    };
  },
  computed: {
    pageText() {
      const pageStart = (this.listSize * this.listPage) + 1;
      const pageEnd = this.listSize * (this.listPage + 1);
      return ` 件中 ${pageStart}～${pageEnd} 件を表示`;
    },
  },
  mounted() {
    this.getCourseList();
    this.getTeachingMaterialByStudent();
  },
  methods: {
    getCourseList(data) {
      this.tableIsLoading = true;
      request('getCourseList', data).then((res) => {
        this.listTotal = res.count;
        this.courseList = res.studentCourseResponseResourceList;
      }).finally(() => {
        this.tableIsLoading = false;
      });
    },
    getTeachingMaterialByStudent(data) {
      this.tableIsLoading = true;
      request('getTeachingMaterialByStudent', data).then((res) => {
        this.materialList = res.studentTeachingMaterialList;
        this.testList = res.studentUnitTestList;
        if(this.materialList.length === 0 && this.testList.length > 0){
          this.activeName = 'second'
        }
        this.dataInit()
      }).finally(() => {
        this.tableIsLoading = false;
      });
    },
    pageChange() {
      this.getCourseList();
    },
    goDetail(id) {
      this.$router.push({
        path: `/course/${id}`,
      }).catch(() => {});
    },
    teachingMaterialpageChange(item){
      this.teachingMaterialpage = item
      if(this.materialList.length>item*6){
        this.materialListCurrent = this.materialList.slice((item-1)*6 ,item*6);
      }else{
        this.materialListCurrent = this.materialList.slice((item-1)*6 ,this.materialList.length);
      }
    },
    testListpageChange(item){
      this.testListpage = item
      if(this.testList.length>item*6){
        this.testListCurrent = this.testList.slice((item-1)*6 ,item*6);
      }else{
        this.testListCurrent = this.testList.slice((item-1)*6 ,this.testList.length);
      }
    },
    dataInit(){
      this.teachingMaterialPageCalculate = Math.ceil(this.materialList.length/6)
      if(this.teachingMaterialPageCalculate > 1){
        this.materialListCurrent = this.materialList.slice(0,6)
      }else{
        this.materialListCurrent = this.materialList.slice();
      }
      
      this.testListPageCalculate = Math.ceil(this.testList.length/6)
      if(this.testListPageCalculate > 1){
        this.testListCurrent = this.testList.slice(0,6)
      }else{
        this.testListCurrent = this.testList.slice();
      }
    },
    showPDF(id) {
      const { courseId, subjectId } = this.$route.params;
      const unitId = this.$route.params.id;
      const materialId = id;
      this.$router.push({
        path: `/showPDF/${courseId}/${subjectId}/${unitId}/${materialId}`,
      }).catch(() => {});
    },
    showVideo(id) {
      const { courseId, subjectId } = this.$route.params;
      const unitId = this.$route.params.id;
      const materialId = id;
      this.$router.push({
        path: `/showVideo/${courseId}/${subjectId}/${unitId}/${materialId}`,
      }).catch(() => {});
    },
    showHtml(id) {
      const { courseId, subjectId } = this.$route.params;
      const unitId = this.$route.params.id;
      const materialId = id;
      
      this.$router.push({
        path: `/showHtml/${courseId}/${subjectId}/${unitId}/${materialId}`,
      }).catch(() => {});
    },
    showTeachingMaterial(item){
      const courseId = item.courseId;
      const subjectId = item.subjectId;
      const unitId = item.unitId;
      const materialId = item.teachingMaterialId;
      if(item.contentType === '01'){
        this.$router.push({
          path: `/showVideo/${courseId}/${subjectId}/${unitId}/${materialId}`,
        }).catch(() => {});
      } else if (item.contentType === '03'){
        this.$router.push({
          path: `/showPDF/${courseId}/${subjectId}/${unitId}/${materialId}`,
        }).catch(() => {});
      } else if (item.contentType === '08'){
        this.$router.push({
          path: `/showHtml/${courseId}/${subjectId}/${unitId}/${materialId}`,
        }).catch(() => {});
      }

    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    goTest(item) {
      //const { courseId, subjectId } = this.$route.query;
      const courseId = item.courseId;
      const subjectId = item.subjectId;
      const unitId = item.unitId;

      this.$router.push({
        path: `/test?courseId=${courseId}&subjectId=${subjectId}&unitId=${unitId}`,
      }).catch(() => {});
    },
  },
};
</script>

<style lang="less">
.home {
  margin: 30px 0;

  // .app-list-sorter-page {
  //   display: block;
  // }
  .app-list-title {
    font-weight:bold;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .home {
    margin: 10px 0;
  }
}

.el-tabs__item:hover {
    color:black;
}

.el-tabs__item.is-active{
    color:white;
    background-color: #ED6D6D
}
.el-tabs__item{
  color:black;
  background-color: #D4ECF4
}
</style>